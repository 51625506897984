<template>
	<div class="tooltip">
		<div
			class="tooltip-nutr"
			v-if="nutrition"
		>
			<div class="tooltip-nutr__headline">
				<div class="tooltip-nutr__name">Пищевая ценность:</div>
			</div>
			<div class="tooltip-nutr__row tooltip-nutr__prot">
				<div class="tooltip-nutr__title">Белки</div>
				<div class="tooltip-nutr__dotts"></div>
				<div class="tooltip-nutr__value">{{ Math.round(nutrition.belki * 0.01 * nutrition.weight) }} гр.</div>
			</div>
			<div class="tooltip-nutr__row tooltip-nutr__carb">
				<div class="tooltip-nutr__title">Жиры</div>
				<div class="tooltip-nutr__dotts"></div>
				<div class="tooltip-nutr__value">{{ Math.round(nutrition.jiri * 0.01 * nutrition.weight) }} гр.</div>
			</div>
			<div class="tooltip-nutr__row tooltip-nutr__fat">
				<div class="tooltip-nutr__title">Углеводы</div>
				<div class="tooltip-nutr__dotts"></div>
				<div class="tooltip-nutr__value">{{ Math.round(nutrition.uglevodi * 0.01 * nutrition.weight) }} гр.
				</div>
			</div>
			<div class="tooltip-nutr__row tooltip-nutr__nutrition">
				<div class="tooltip-nutr__title">Энерг. ценность</div>
				<div class="tooltip-nutr__dotts"></div>
				<div class="tooltip-nutr__value">{{ Math.round(nutrition.calories * 0.01 * nutrition.weight) }} Ккл.
				</div>
			</div>
		</div>
		<div
			class="tooltip-rest-info"
			v-if="info"
		>
			<div
				class="tooltip-rest-info__item"
				v-html="returnActiveRestaurantName"
			></div>
			<div class="tooltip-rest-info__item">ОГРН {{ returnActiveRestaurantOgrn }}
			</div>
			<div class="tooltip-rest-info__item">
				ИНН {{ returnActiveRestaurantInn }}
			</div>
		</div>
		<div
			class="tooltip-product-list"
			v-if="list"
		>
			<div class="tooltip-product-list__text">
				<span
					class="tooltip-product-list__text-item"
					v-for="(sostavItem, index) of list"
				>{{ sostavItem }}</span>
			</div>
		</div>
		<div
			class="tooltip__arr"
			:class="{'tooltip__arr_bottom' : info, 'reverse': reverseArrow }"
		></div>
	</div>
</template>

<script>
export default {
	props: ['nutrition', 'info', 'list', 'reverseArrow'],
	data()
	{
		return {
			curRest: false,
		}
	},
	computed:
		{
			/**
			 * название текущего ресторана
			 */
			returnActiveRestaurantName()
			{
				for (let restaurant of this.$store.state.restaraunts)
					if (restaurant.id == this.$store.state.address.restarauntId)
					{
						this.curRest = restaurant;
						return restaurant.name;
					}

				return '«Название Ресторана»'
			},
			/**
			 * инн текущего ресторана
			 */
			returnActiveRestaurantInn()
			{
				if (this.curRest) return this.curRest.inn;

				return ''
			},
			/**
			 * огрн текущего ресторана
			 */
			returnActiveRestaurantOgrn()
			{
				if (this.curRest) return this.curRest.ogrn;

				return ''
			},
		}
}
</script>

<style lang="scss">

.tooltip
{
	position: absolute;
	background-color: $textColor;
	max-height: 126px;
	width: 226px;
	padding: 12px 15px 15px;
	border-radius: 10px;

	&__arr
	{
		position: absolute;
		background-color: $textColor;
		width: 20px;
		height: 20px;
		bottom: -10px;
		left: 98px;
		transform: rotate(45deg);

		&_bottom
		{
			bottom: -4px;
			right: 11px;
			left: unset;
		}
	}
}

.right-aligned .tooltip__arr
{left: 163px;}

.tooltip-nutr
{
	cursor: default;
	@include ease(.125s);
}

.tooltip-nutr__row
{
	display: flex;
	justify-content: space-between;
	z-index: 12;

	&:not(:last-child)
	{
		margin-bottom: 10px;
	}
}

.tooltip-nutr__title, .tooltip-nutr__value
{
	color: #fff;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 100%;
	z-index: 12;
	letter-spacing: .1px;
}

.tooltip-nutr__name
{
	color: #fff;
	font-style: normal;
	font-size: 12px;
	line-height: 100%;
	background-color: $textColor;
	z-index: 12;
	font-family: $mainFontMedium;
	font-weight: bold;
	letter-spacing: .1px;
	margin-bottom: 12px;
}

.tooltip-nutr__value
{
	color: #fff;
}

.tooltip-nutr__dotts
{
	background-image: linear-gradient(to right, #fff 20%, $textColor 0%);
	background-position: 0 11px;
	background-size: 5px 1px;
	background-repeat: repeat-x;
	flex: 1;
}

.tooltip-rest-info
{
	color: #fff;
	font-size: 16px;
	font-family: $mainFont;
	line-height: 100%;
	background-color: $textColor;
	letter-spacing: .1px;
}

.tooltip-rest-info__item
{
	font-family: $mainFontNew;

	&:not(:last-child)
	{
		padding-bottom: 10px;
	}
}

.tooltip-product-list__text
{
	font-family: $mainFont;
	font-size: 14px;
	color: #fff;
}

.tooltip-product-list__text-item
{
	&:not(:last-child)
	{
		&:after
		{
			content: ', ';
		}
	}
}


@media (max-width: 1024px)
{
	.tooltip__arr_bottom
	{
		top: -10px;
		left: unset;
		right: 30px;
	}
}

@media (max-width: 767px)
{
	.tooltip__arr
	{
		bottom: 9px;
		left: -8px;

		&_bottom
		{
			top: -10px;
			left: unset;
			right: 30px;
		}
	}

	.right-aligned .tooltip__arr
	{left: 205px;}

	.reverse
	{
		display: none;
	}
}

</style>